((hero) => {
    const video = hero.querySelector('.hero__video video');
    const heroRotate = (offset = 0) => {
        // All the messages
        const messages = hero.getElementsByClassName('hero__message');

        // Bring in the first one immediately
        if (messages[0].dataset.timing == '0') {
            let active = hero.getElementsByClassName('hero__message--active')[0];
            if (active) active.classList.remove('hero__message--active');
            messages[0].classList.add('hero__message--active');
        }

        // Wait until the video really is likely to be playing
        setTimeout(() => {
            for (let message of messages) {
                // Schedule the changes with offsets for the current time
                setTimeout(
                    () => {
                        let active = hero.getElementsByClassName('hero__message--active')[0];
                        if (active) active.classList.remove('hero__message--active');
                        message.classList.add('hero__message--active');
                    },
                    parseInt(message.dataset.timing) - 1000 * video.currentTime
                );
            };
        }, 950);
    };

    video.addEventListener('play', (e) => {
        heroRotate();
    })
    video.addEventListener('seeked', (e) => {
        heroRotate();
    });
})(document.querySelector('.hero'));
